body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background: linear-gradient(to right, rgba(27, 251, 153,1) 0%, rgba(132, 129, 221,1) 100%);*/
  background: linear-gradient(to right, rgba(68, 59, 105,1) 0%, rgba(196, 37, 248,1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'SEGA LOGO FONT';
font-style: normal;
font-weight: normal;
src: local('SEGA LOGO FONT'), url(../../static/media/SEGA.12a3db8b.woff) format('woff');
}

h1 {
  font-family: 'SEGA LOGO FONT';
  font-weight: 200;
  color: #0ccac4;
}
